@import "@styles/theme", "@styles/mixins", "@styles/animations";

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  .previous {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 20%;
    height: calc(100% - 34px);
    z-index: 4;
  }

  .next {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 20%;
    height: calc(100% - 34px);
    z-index: 4;
  }
}

.story {
  position: absolute;
  user-select: none;

  img {
    user-select: none;
  }
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  object-fit: cover;
  object-position: bottom;
  z-index: 0;
}

.story {
  &,
  & > div {
    width: 100%;
    height: 100%;
  }
}

.clickCta {
  position: absolute;
  z-index: 1;
  top: 35px;
  right: 30px;
  display: flex;
  flex-direction: row;

  p {
    display: inline-block;
  }
  svg {
    margin-right: 10px;
  }
}

.imageHolder {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.scrollCta {
  position: absolute;
  z-index: 1;
  bottom: 16px;
  left: 16px;
  height: 167px;
  width: 18px;
  background: url("../../../public/images/ui/scrollVertical.svg") no-repeat
    center;
}

.container {
  position: relative;
  justify-items: start;
  overflow: hidden;
  background-color: $background-primary;
  align-items: start;
  width: 100%;
  height: calc(100vh - #{$header-size});
  min-height: calc(#{$min-mobile-height} - 44px);
}

.loadinBar {
  animation-name: loading;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.nextTopic {
  position: absolute;
  right: toRem(18);
  bottom: 15%;
  color: $text-primary;
  font-weight: bold;
  font-size: toRem(18);
  z-index: 2;

  svg {
    color: $border-primary;
    margin-left: toRem(9);
    height: toRem(12);
    width: auto;
  }
}

.gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background: linear-gradient(
    0deg,
    rgba($background-primary, 1) 0%,
    rgba($background-primary, 0) 100%
  );
}

.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 20px;
  height: 34px;
  display: flex;
  z-index: 2;
  justify-content: space-between;

  .barItemHolder {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .barItem {
    width: 100%;
    height: 4px;
    background-color: $input-box-primary;
    &.hasGone {
      background-color: #fff;
    }
  }
}

.nextCard {
  position: absolute;
  z-index: 5;
  bottom: 0;
  right: 0;
  display: block;
  font-family: $font-family-bold;
  color: $white;
  font-size: 16px;
}
