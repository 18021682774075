@import "@styles/theme";

.button {
  flex-shrink: 0;
  background-color: $button-color-bg;
  &.secondary {
    background-color: $text-secondary;

    &:after {
      border: 1px solid $text-secondary;
    }
  }
  &.light {
    background-color: $secondary;

    &:after {
      border: 1px solid $secondary;
    }
  }
  color: $text-primary;
  width: 36px;
  height: 36px;
  &.large {
    width: 52px;
    height: 52px;
  }
  position: relative;
  transition: background-color $transition-time-quickest $transition-easing;

  &:hover,
  &:active {
    &:not(.disabled) {
      color: $primary;
      border-width: 1px !important;
      border-style: solid !important;
      background-color: $background-secondary;
      &.secondary {
        color: $text-secondary;
      }
      &.light {
        color: $secondary;
      }

      &:after {
        border-color: $background-secondary;
      }
    }
  }

  &:active {
    &:not(.disabled) {
      &:after {
        left: 0px !important;
        top: 0px !important;
        border-color: $primary !important;
      }
      &.secondary:after {
        border-color: $text-secondary !important;
      }
      &.light:after {
        border-color: $secondary !important;
      }
    }
  }
  &.large:after {
    width: 51px;
    height: 51px;
    border-color: $secondary !important;
  }

  &.previous:after {
    left: -4px;
  }

  &.previous svg {
    transform: rotate(180deg);
  }

  &.disabled {
    opacity: 0.3 !important;
    cursor: default;
  }
}
