@import '@styles/theme', '@styles/mixins';

.columnDivider {
	max-width: $screen-max-size;
	margin: 0 auto;
	background-color: #1c1c1c;

	@include desktop {
		padding-left: $nav-width-desktop;
	}
}

.container {
	position: relative;
	margin: 0 auto;
	padding-top: $header-size;
	width: 100%;

	@include desktop {
		padding-top: 0;
	}
}