@import "@styles/theme", "@styles/mixins";

.container {
  overflow: hidden;

  &:not(.noPadding) {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  @include desktop {
    padding-top: 14px;
    padding-top: inherit;
  }

  position: relative;

  &.hardHeight {
    height: calc(100vh - #{$header-size});

    @include desktop {
      height: 100vh;
    }
  }

  &.softHeight {
    min-height: 550px;

    @include desktop {
      min-height: 100px;
    }
  }
}
