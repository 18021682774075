@import "@styles/theme", "@styles/mixins", "@styles/animations";

.main {
  background-color: #18131f;
}

.container {
  padding: 0 25px;
}

.versions {
  padding: 40px 0;
  background: url("../../../../../public/images/ui/bgVersionsMobile.webp")
      no-repeat center 240px,
    linear-gradient(to top, #18131f 0 50%, #edede3 50% 100%);
  background-size: contain;

  .bars {
    display: block;
    content: " ";
    width: 129px;
    height: 49px;
    background-image: url("../../../../../public/images/ui/barsVersionsMobile.png");
    position: absolute;
    left: calc(100vw - 85%);
    bottom: 700px;
    background-repeat: no-repeat;
  }

  .container {
    .title {
      display: flex;
      text-align: center;
      margin: 0 0 30px;
      justify-content: center;
      h2 {
        display: flow;
        font-family: $font-family-extra-bold;
        font-size: 24px;
        color: #18131f;
        text-transform: uppercase;
        span {
          color: #991241;
        }
      }
    }
  }

  .currCar {
    color: #262626;
    opacity: 30%;
    font-weight: bold;
    font-size: 94px;
    position: absolute;
    text-transform: uppercase;
    top: 140px;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, 0);
  }

  .btnPrev,
  .btnNext {
    display: none;
  }

  .carNames {
    height: 35px;
    button {
      font-family: $font-family-extra-bold;
      text-transform: uppercase;
      color: #991241;
      border-bottom: 1px solid #991241;
      padding: 5px 15px;
      &.active {
        background-color: #991241;
        color: #fff;
        border: none;
        -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
        box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
      }
    }
  }

  .carousel {
    width: 100%;
    text-align: center;

    .images {
      display: flex;
      width: 50%;
      object-fit: contain;
    }
  }

  .colorsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2.5rem;

    p {
      font-size: 16px;
      font-weight: bold;
      align-self: center;
      color: #fff;
      width: auto;
    }
  }

  .colors {
    margin-top: 5px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 12px 0;
    align-items: center;
    border-radius: 50px;

    nav {
      display: flex;
      margin: 0 0.6rem;

      button {
        margin: 0 10px;
        position: relative;
        width: 16px;
        height: 16px;
        border: none;

        &.active {
          &::after {
            position: absolute;
            top: -7px;
            left: -7px;
            display: block;
            width: 26px;
            height: 33px;
            content: url("../../../../../public/images/ui/marker.svg");
            z-index: 1;
          }

          .currCircleColor {
            border-radius: 8px;
            outline: 1px solid #000;
          }

          .currTextColor {
            display: block;
            position: absolute;
            top: 36px;
            left: 50%;
            color: #fff;
            text-align: center;
            transform: translateX(-50%);
            font-family: $font-family-bold;
            font-size: 8px;
          }
        }

        .currCircleColor {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          border-radius: 8px;
          outline: 1px solid #fff;
        }

        .currTextColor {
          white-space: nowrap;
          display: none;
        }
      }
    }
  }

  .info {
    margin: 20px 25px 0;
    .optional {
      display: flex;
      flex-direction: column;
      min-height: 230px;
      .title {
        display: block;
        padding-bottom: 10px;
        margin-bottom: 33px;
        h3 {
          font-weight: 600;
          font-size: 1.3rem;
          text-transform: uppercase;
          position: relative;
          color: #fff;
          font-family: $font-family-extra-bold;
          font-size: 24px;

          &:before {
            position: absolute;
            bottom: -10px;
            left: 0;
            content: " ";
            display: block;
            height: 3px;
            width: 100%;
            background-color: #991241;
          }
          span {
            font-size: 18px;
            color: #991241;
          }
        }
      }

      p {
        font-size: clamp(1rem, 1vw, 1rem);
        color: #ccc;
      }
      .thumbs {
        list-style: none;
        margin: 0px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        li {
          display: flex;
          width: 104px;
          margin-right: 16px;
          &:last-child {
            margin-right: 0;
          }
          .thumbContainer {
            display: flex;
            flex-direction: column;
            .imageContainer {
              display: flex;
              width: 100%;
            }
            .titleContainer {
              flex-grow: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 48px;
              text-align: center;
              background-color: #991241;
              h4 {
                color: #fff;
                font-family: $font-family-bold;
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .price {
      margin-top: 35px;
      .payment {
        p {
          font-family: $font-family-demi;
          font-size: 14px;
          color: #991241;
          text-transform: uppercase;

          strong {
            font-family: $font-family-bold;
            font-size: 22px;
            color: #fff;
          }
        }
      }

      .ctas {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        margin-top: 1rem;
        align-items: flex-end;

        .ctaButtonDownload {
          margin-bottom: 0.5rem;

          > div {
            padding: 0.7rem;

            svg {
              width: 13px;
              height: 13px;
            }

            p {
              display: flex;
              justify-content: space-between;
            }
          }
        }

        .ctaLinks {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
        .interest {
          > div {
            svg {
              width: 13px;
              height: 13px;
            }
          }
        }

        .ctaButton {
          margin-bottom: 0.5rem;

          > div {
            padding: 0.7rem;

            svg {
              width: 13px;
              height: 13px;
            }

            p {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }

  @include desktop {
    background: url("../../../../../public/images/ui/bgVersionsDesktop2.webp")
        no-repeat center bottom,
      linear-gradient(to top, #18131f 0 498px, #edede3 498px 100%);

    .bars {
      width: 308px;
      height: 131px;
      background-image: url("../../../../../public/images/ui/barsVersionsDesktop.png");
      bottom: 498px;
    }
    .container {
      .title {
        margin: 0 0 43px;
        h2 {
          font-size: 32px;
        }
      }
    }
    .btnPrev,
    .btnNext {
      display: block;
      position: absolute;
      z-index: 10000;
      width: 48px;
      height: 48px;
      bottom: 140px;
      margin-top: -20px;
      background-color: $button-color-bg;
      color: $button-color-text;
      &.active {
        opacity: 50%;
      }
    }
    .btnPrev {
      left: 0;
    }
    .btnNext {
      right: 0;
    }
    .carNames {
      display: flex;
      justify-content: center;
      margin: 0 0 50px;
      height: 26px;
      button {
        margin-right: 18px;
        padding: 5px 16px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .info {
      margin: 20px 25px 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .optional {
        .title {
          margin-bottom: 18px;

          h3 {
            font-size: 32px;
          }
        }
        .thumbs {
          li {
            margin-right: 10px;
          }
        }
      }
    }
    .price {
      margin-top: 0;
      .payment {
        text-align: right;
      }
      .ctas {
        min-width: 300px;
      }
    }
  }
}
