.accordion {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  color: #fff;
  font-size: large;
  cursor: pointer;
  padding: clamp(12px, 2vh, 35px) 0 clamp(12px, 2vh, 35px) 20px;
  width: 100%;
  border-bottom: 1px solid $divider-color !important;
  text-align: left;
  border: none;
  outline: none;
  transition: all $transition-time linear;

  p {
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.accordion > .chevron {
  transition: all $transition-time-quick $transition-easing;
  transform: rotate(0);
  margin-left: 0px;
  margin-right: 16px;
}

.accordion.active > .chevron {
  transform: rotate(180deg);
}

.panel {
  padding-bottom: 0;
  background-color: transparent;
  max-height: 0;
  overflow: hidden;
  transition: all $transition-time linear;
  position: relative;
}
