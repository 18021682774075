// BRAND COLORS
$primary: #18131f;
$secondary: #991241;
$tertiary: #268f52;
$quaternary: #ff1430;
$quinary: #edede3;
$black: #000;
$white: #fff;
$gray: #393939;

// BACKGROUNDS PLATES COLORS
$background-primary: #1c1c1c;
$background-secondary: #edede3;
$background: #272727;
$background-dark: #18131f;
$background-darker: #393939;
$background-light: #edede3;
$bullet-color: #fff;
$bullet-color-active: #991241;
$divider-color: #ffffff;
$divider-color-active: #ff1430;
$background-header: #272727;

// BUTTONS
$button-color-bg: #268f52;
$button-color-text: #ffffff;

$button-color-bg-active: #ffffff;
$button-color-text-active: #268f52;

// FONTS COLORS
$text-primary: #ffffff;
$text-secondary: #272727;
$text-on-primary: #3a369e;
$text-on-secondary: #272727;
$text-menu-link: #268f52;
$text-banner-description: #cccccc;
$hr-color: #9fcfd9;
$text-light: #fff;

$text-menu: #ffffff;
$text-menu-active: #ff1430;
$text-desk-menu-active: #268f52;
$border-desk-menu-active: #268f52;

// INPUTS COLORS
$input-box-primary: #999891;
$input-box-secondary: #636363;
$border-color: #17131e;
$font-family-medium: "Futura PT Medium";
$font-family-light: "Futura PT Light";
$font-family-demi: "Futura PT Demi";
$font-family-bold: "Futura PT Bold";
$font-family-extra-bold: "Futura PT ExtraBold";
$font-family-heavy: "Futura PT Heavy";
$font-family-medium-oblique: "Futura PT Medium Oblique";
$font-family-light-oblique: "Futura PT Light Oblique";
$font-family-demi-oblique: "Futura PT Demi Oblique";
$font-family-bold-oblique: "Futura PT Bold Oblique";
$font-family-extra-bold-oblique: "Futura PT ExtraBold Oblique";
$font-family-heavy-oblique: "Futura PT Heavy";
$fontawesome: "font awesome";
$fontawesome-brands: "font awesome brands";
$scrollbar-track: rgba(#000000, 0.23);
$scrollbar-body: #ffffff;
$border-primary: $secondary;
$border-size: clamp(8px, 2vh, 18px);

$section-horizontal-padding: clamp(40px, 4vw, 90px);
$section-horizontal-padding-desktop: clamp(70px, 4vw, 90px);

$min-mobile-height: 568px;

$container-max-width: 1280px;

$font-weight-thin: 100;
$font-weight-ultralight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 800;
$font-weight-extrablack: 900;

$header-size: 44px;
$header-size-desktop: 52px;

$transition-time: 400ms;
$transition-time-quick: 200ms;
$transition-time-quickest: 50ms;
$transition-easing: ease-in-out;

$nav-width: 224px;
$nav-width-desktop: 0px;

$screen-sm-min: 576.1px;
$screen-md-min: 768.1px;
$screen-lg-min: 992.1px;
$screen-desktop-min: 1024.1px;
$screen-xl-min: 1200.1px;
$screen-xxl-min: 1920.1px;
$screen-max-size: 2560px;
