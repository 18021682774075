@import "@styles/theme", "@styles/mixins", "@styles/animations";

.container {
  box-shadow: 0px 0px 0px 0px transparent;
  transition: box-shadow, left $transition-time-quick $transition-easing;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  width: 100%;
  border-right: 55px solid $background-darker;
  display: none;

  &.open {
    display: block;
    left: 0;
    box-shadow: 0px 0px 20px 0px rgba($background-dark, 0.5);
    overflow-y: scroll;
    .scroller {
      position: absolute;
      top: 53px;
      height: 100vh;
      width: 100%;

      a {
        &:not(.menuButton) {
          display: flex;
          color: $text-menu;
          font-size: 1.5rem;
          line-height: 120%;
          text-decoration: none;
          transition: all 200ms ease-in-out;
          border-bottom: 1px solid $divider-color;
          padding: 15px 0;
          margin: 0 15px;
          &.active {
            color: $divider-color-active;
            border-bottom: 3px solid $divider-color-active;
            padding: 15px 0 12px;
          }
        }
        margin: 0 0 15px;
      }

      .buttonsHolder {
        display: flex;
        flex-flow: column;
        padding: 80px 15px;
      }
    }
  }

  @include desktop {
    display: block;
    background-color: $background-header;
    height: 52px;
    width: 100%;
    padding: 0;
    margin: 0;
    position: fixed;
    border: none;

    overflow: hidden;
    .scroller {
      display: flex;
      height: inherit;

      .productLogo {
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        background-color: $black;
        width: 156px;
      }
      .menuItemArea {
        display: flex;
        flex: auto;
        > div {
          position: relative;
          display: flex;
          &:not(:last-child):before {
            display: block;
            position: absolute;
            content: " ";
            width: 1px;
            height: 16px;
            background-color: #555;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
          }
          &:last-child a {
            padding: 0 0 0 12px;
          }
          a {
            display: flex;
            align-items: center;
            padding: 0 12px;
            color: $text-primary;
            text-align: center;
            &.active,
            &:hover {
              color: $text-desk-menu-active;
              border-bottom: 3px solid $border-desk-menu-active;
              span {
                margin-top: 3px;
              }
            }
          }
        }
      }
      .buttonsHolder {
        display: flex;
        align-items: center;
        width: 370px;
        justify-content: space-between;
        margin: 0 16px;
        .menuButton {
          max-width: 180px;
        }
      }
    }
  }
}

.gestureDetector {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 999;
}
