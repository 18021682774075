@import "@styles/theme", "@styles/mixins";

@mixin buttonHover {
  &:not(.secondary):not(.light) {
    background-color: $button-color-bg-active;
    color: $button-color-text-active;
  }
  &:not(.disabled) {
    &:after {
      border-color: $background-secondary;
    }
    &.secondary {
      color: $button-color-text-active;
    }
    &.light {
      &:after {
        border-color: $secondary !important;
      }
    }
  }
}

.button {
  flex-shrink: 0;
  background-color: $button-color-bg;
  color: $button-color-text;
  width: 48px;
  height: 48px;
  &.large {
    width: 52px;
    height: 52px;
  }
  position: relative;
  transition: background-color $transition-time-quickest $transition-easing;

  &:active {
    background-color: $button-color-bg-active;
    color: $button-color-text-active;
  }

  @include desktop {
    &:hover {
      @include buttonHover;
      background-color: $button-color-bg-active;
      color: $button-color-text-active;
    }
  }

  &:active {
    &:not(.disabled) {
      &:after {
        left: 0px !important;
        top: 0px !important;
        width: 33px;
        height: 33px;
        border-color: $primary !important;
      }
      &.secondary:after {
        border-color: $text-secondary !important;
      }
      &.light:after {
        border-color: $secondary !important;
      }
    }
  }
  &.large:after {
    width: 49px;
    height: 49px;
    left: 4px;
    top: 4px;
    border-color: $secondary !important;
  }

  &.previous:after {
    left: -4px;
  }

  &.previous svg {
    transform: rotate(180deg);
  }

  &.disabled {
    opacity: 0.3 !important;
    cursor: default;
  }
}
