@import "@styles/theme";

.button {
  border: 1px solid transparent;
  &:hover,
  &:active {
    .content {
      color: $button-color-text-active;
    }
    // border-color: $primary;
    svg {
      color: $button-color-text-active;
    }
    .contentHolder {
      background-color: $button-color-bg-active;
      color: $button-color-text-active;
      &:before,
      &:before {
        background-image: url(../../../public/images/ui/iconStripesActive.svg);
      }
    }
  }
  &.larger {
    .contentHolder {
      padding: 12px 24px;
    }
    .content {
      font-size: 1.2rem;
    }
  }

  .content {
    &.withIcon:after {
      content: "\f019";
      font-family: $fontawesome;
      font-size: 0.9rem;
      margin-right: 3px;
    }
  }

  svg {
    color: $text-primary;
    flex-shrink: 0;
  }

  .contentHolder {
    background-color: $button-color-bg;
    color: $button-color-text;
    transition: background-color $transition-time-quickest $transition-easing;
    padding: 12px 15px;
    display: flex;
    align-items: center;
    position: relative;
    &:before {
      position: absolute;
      top: 0px;
      left: 15px;
      z-index: 2;
      content: " ";
      background-image: url(../../../public/images/ui/iconStripes.svg);
      width: 17px;
      height: 5px;
    }
    p {
      font-family: $font-family-demi;
      font-size: 0.90rem;
      font-weight: 500;
    }
  }
}
