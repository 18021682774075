@import "@styles/theme", "@styles/mixins", "@styles/animations";

.positionFix {
  position: absolute;
  bottom: 66px;
  right: 48px;

  .container {
    .cards {
      background-image: url("../../../public/images/ui/cardBar.svg");
      background-repeat: no-repeat;
      background-position: center 44px;
      padding: 0 0px 53px 35px;
      background-size: contain;
      .unitaryBox {
        padding: 0;
        .externalBox {
          position: relative;
          width: 185px;
          height: 88px;
          &:before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: block;
            content: " ";
            width: 185px;
            height: 88px;
            background-image: url("../../../public/images/ui/cardOverlay.svg");
            background-repeat: no-repeat;
            background-position: 0px 0px;
          }
          &.selected:before,
          &:hover:before {
            background-image: url("../../../public/images/ui/cardOverlaySelected.svg");
          }
          .carPicture {
            position: absolute;
            top: 0px;
            left: 17px;
            display: block;
            width: 168px;
            height: 88px;
          }
          .cardTitleBox {
            margin-top: 5px;
            .cardTitle {
              color: $white;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
