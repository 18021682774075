@import '@styles/theme', '@styles/mixins';

.container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 4000;
  background-color: $background-primary;
  .bannerLink{
    color: #B0A477;
  }
  > div {
    position: relative;
    padding: #{toRem(40)} #{toRem(25)} #{toRem(25)};

    p {
      max-width: $screen-max-size;
      margin: 0 auto;
    }

    a, p {
      font-size: toRem(14);
    }

    .closeButton {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}